<template>
  <v-container style = "position: absolute; z-index: 9; backdrop-filter: blur(5px);" fill-height fluid v-if="showLegal">
    <v-row class="mt-n16" align="center" justify="center">
      <v-col align="center" justify="center">
        <v-card max-width="600px" :min-height="isAdmin ? '500px' : '520px'" color="#f5f5f5">
          <v-card-title>
            <v-row align="center" justify="center" class="mt-4">
              <img  width="145" height="50" src="/content/images/TheStudio_Logo_Lockup-Stacked_Dark.svg" />
            </v-row>
          </v-card-title>
          <v-row class="mt-6">
            <v-col>
              <v-card v-if="notAdminAndReminderSent" outlined  max-width="500px" align="start" justify="center" class="pa-md-4 mx-lg-auto">
                <v-row>
                  <v-col>
                    <h3 class="font-weight-bold">We have informed all administrators and are awaiting their acceptance.</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span>Your organization's administrator needs to accept the terms and conditions for your access to be restored.</span>
                    <p class="mt-4">We apologize for any inconvenience this may cause and appreciate your patience. Once the admin completes this process, you will be able to continue using the application as usual.</p>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col>
                    <v-btn class="entity-button-text" block  color="primary"><v-icon small class="mr-2">mdi-refresh</v-icon>Check again</v-btn>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-else outlined  max-width="500px" align="start" justify="center" class="pa-md-4 mx-lg-auto">
                <v-row>
                  <v-col>
                    <h3 class="font-weight-bold">ELB Learning Terms of Services</h3>
                  </v-col>
                </v-row>
                <v-row class="mt-n6">
                  <v-col>
                    <span >Please review and accept the following <span v-for="(legal, index) in legalContentList" :key="index"> <a :href="legal.link" target="_blank" style="color: #1D4ED8;">{{ legal.context }}</a><span v-if="index < legalContentList.length -1"> and </span></span>.</span>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col>
                    <span ><strong>Account:</strong>&nbsp;{{ oganizationName }}</span>
                  </v-col>
                </v-row>
                <div>
                  <v-row class="mt-2" v-if="!isAdmin">
                    <v-col>
                      <v-alert 
                        type="warning"
                        outlined
                        text
                        color="#E49B0F"
                        icon="mdi-alert"
                      >You must be an admin to accept the terms.</v-alert>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col>
                      <v-btn class="entity-button-text" outlined>
                        Send reminder to admin
                      </v-btn>
                    </v-col>
                  </v-row> -->
                </div>
                <v-row class="mt-6">
                  <v-col v-if="isAdmin">
                    <span>By selecting "Accept" you agree to the ELB Learning Terms and Conditions for {{ oganizationName }}.</span>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col>
                    <v-btn v-if="isAdmin" block color="primary" :disabled="!isAdmin" class="entity-button-text" @click="agree()">Accept</v-btn>
                    <v-btn v-else class="entity-button-text" block  color="primary" @click="markExempt()">Send reminder to admin</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>         
      </v-col>
    </v-row> 
  </v-container>
</template>
<script lang="ts" src="./agreement.component.ts">
</script>
<style scoped>
  .card-profile {
      max-width: 180px;
      border-radius: .25rem;
      transform: translate(-50%,-30%);
      position: absolute;
      left: 50%;
      transition: all .15s ease;
  }
  .rounded-circle {
      border-radius: 50%!important;
  }
  
  .row {
      margin-right: 0px;
      margin-left: 0px;
  }
  
  .smaller-text {
      font-size: 11px;
  }
  .dialog-header {
      background-color: '#f4f4f4';
  }
  </style>