<template>
    <v-container style = "position: absolute;
    z-index: 9;
    backdrop-filter: blur(5px);" fill-height fluid >
      <v-row align="center" justify="center" >
          <v-col align="center" justify="center">
            <v-card flat max-width="500px" class="login-box">
              <v-alert v-if="authenticationError"  type="error" dismissible>
                  <span v-text="$t('login.messages.error.authentication')"><strong>Failed to sign in!</strong> Please check your credentials and try again.</span>
              </v-alert>
            </v-card>
            <v-card outlined max-width="460px" class="mt-16">
              <v-card
                    class="mx-auto card-profile rounded-circle"
                    height="80"
                    max-width="80"
                >
                    <v-avatar
                        class="profile rounded-circle "
                        size="80"
                        tile
                    >
                        <v-img :src="settingsAccount.imageUrl" ></v-img>
                    </v-avatar>
                </v-card>
               <v-row class="mt-16" align="center" justify="center" >
                <v-col cols="10" class="toastify-container">
                  <form role="form" v-on:submit.prevent="doLogin()">
                    <v-row>
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="password"
                        id="password"
                        name="password"
                        :append-icon="hidden ? 'visibility' : 'visibility_off'"
                        @click:append="() => (hidden = !hidden)"
                        :type="hidden ? 'password' : 'text'"
                        v-bind:label="$t('login.form.password')"
                        prepend-inner-icon="mdi-lock"
                        hide-details="auto"
                        
                        clearable
                        outlined
                        dense
                        @focus="authenticationError=false"
                      />
                    </v-row>
                    <v-row align="center" justify="space-around">
                      <v-col cols="4">&nbsp;</v-col>
                      <v-col cols="4">
                        <v-btn
                          color="secondary"
                          type="submit"
                          block
                          v-text="$t('login.form.button')"
                          class="white--text"
                          :loading="loading"
                          :disabled="loading">
                          Sign in
                        </v-btn>
                      </v-col>
                      <v-col cols="4">&nbsp;</v-col>
                    </v-row>
                  </form>
                </v-col>
               </v-row>
               <v-overlay
                :absolute="true"
                :value="loading"
               >
                <v-progress-circular
                  indeterminate
                  size="60"
                ></v-progress-circular>
               </v-overlay>
            </v-card>
          </v-col>
      </v-row>
    </v-container>
  </template>
  <script lang="ts" src="./reauthenticate.component.ts">
</script>
<style scoped>
  .card-profile {
      max-width: 180px;
      border-radius: .25rem;
      transform: translate(-50%,-30%);
      position: absolute;
      left: 50%;
      transition: all .15s ease;
  }
  .rounded-circle {
      border-radius: 50%!important;
  }
  
  .row {
      margin-right: 0px;
      margin-left: 0px;
  }
  
  .smaller-text {
      font-size: 11px;
  }
  .dialog-header {
      background-color: '#f4f4f4';
  }
  </style>