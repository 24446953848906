$body-font-family: "proxima-nova" !important;
@import './_colors';

$brand-primary: $purple;
$brand-success: $green;
$brand-danger: $red;
$brand-warning: $orange;
$brand-info: $cyan;

$padding-logo: 15px;
$padding-avatar-title: 5px;
$padding-x-footer: 24px;

$line-height: 30px;
$line-height-base: 1.5em;
$line-height-footer-items: 1.8;
$line-height-higher: 22px;
$line-height-high: 56px;

$icon-width: $line-height;

$padding-small: 10px;
$padding-general: 15px;
$margin-general: $padding-general;
$margin-small: $padding-small;
$font-size-general: 14px;
$list-item-hover: rgba(200, 200, 200, 0.2);
$list-item-icon-opacity: 0.8;
$list-item-height: 48px;
$badge-border: 1px solid $white;
$badge-dimension: 20px;

$border-radius-base: 3px;

$margin-list-right: 11px;
$margin-list-left: 25px;

$divider-width: calc(100% - 30px);
$divider-margins: -1px;
$divider-border: 1px solid #eeeeee;

$font-size-mini: 9px;
$font-size-small: 12px;
$font-size-default: 14px;
$font-size-avatar-title: 18px;
$font-size-alert-icon: 30px;
$font-size-big: 36px;
$font-size-small-tag: 65%;
$font-size-toolbar-title: $font-size-avatar-title;
$font-size-toolbar-items: 20px;
$font-size-h1: 3.3125rem;
$font-size-h2: 2.25rem;
$font-size-h3: 1.5625rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1.0625rem;
$font-size-h6: 0.75rem;
$font-paragraph: $font-size-default;
$font-size-login-label: 0.73rem;

$font-weight-lighter: 300;
$font-weight-base: 400;
$font-weight-bolder: 500;

$black-opacity-3: rgba(180, 180, 180, 0.3);

$top-dim: 1px;

$mdb-shadow-key-umbra-opacity: 0.2;
$mdb-shadow-key-penumbra-opacity: 0.14;
$mdb-shadow-ambient-shadow-opacity: 0.12;

$toolbar-min-height: 70px;

$toolbar-margin-left: 260px;

$body-color: #eeeeee;

$black-color: #495057;
$blockquote-color: rgba(0, 0, 0, 0.87);

$gray-light: #999999;
$gray: lighten(black, 33.5%);

$input-color: #d2d2d2;
$label-color: #aaaaaa;

$footer-border-color: #e7e7e7;
$footer-border-top: 1px solid $footer-border-color;

$card-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
$card-margin: 25px;
$card-content-x-padding: 20px;
$card-actions-y-margin: $card-content-x-padding;
$card-title-margin: 5px;
$card-offset: -20px;
$card-login-min-height: 160px;
$card-stats-offset: 85px;
$card-stats-icons-dim: 56px;
$card-profile-avatar-pos: -50px;

$font-family-sans-serif: "proxima-nova" "Roboto" "Helvetica" "Arial" sans-serif;
$font-family-serif: "proxima-nova" "Roboto Slab" "Times New Roman" serif;

$tooltip-min-width: 130px;
$tooltip-max-width: 200px;
$tooltip-border: 5px;
$tooltip-top-border-pos: 16px;
$tooltip-arrow-align: 0.255em;

$btn-icon-dim: 41px;
$btn-y-large-padding: 1.125rem;
$btn-x-large-padding: 2.25rem;
$btn-large-line-height: 1.33333;
$btn-y-small-padding: 0.40625rem;
$btn-x-small-padding: 1.25rem;
$btn-round-padding: 30px;
$btn-font-size-icon: 1.25rem;
$btn-margin-icon: 7px;
$btn-fixed-icon-size: 1.8rem;
$btn-fixed-width: 64px;

$tabs-active-line: rgba(255, 255, 255, 0.3);
$tabs-text-box-padding: 8px;

$table-hover-color: whitesmoke;
$table-border-color: rgba(0, 0, 0, 0.06);
$table-head-height: 32px;

$social-facebook: #3b5998;
$social-twitter: #55acee;

$font-size-dropdown: 0.8125rem;
$border-radius-dropdown: 10px;

$box-shadow-general: 0 2px 5px 0 rgba($black, 0.26);
