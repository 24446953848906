<template>
  <div>
    <v-navigation-drawer 
      v-model="sidebarMenu" 
      app 
      clipped  
      :permanent="sidebarMenu" 
      :mini-variant.sync="mini" 
      color="#F3F4F6"
    >
      <v-divider color="white" class="mtn-2"></v-divider>
      <v-list nav dense color="#F3F4F6">
        <template v-for="(item, index) in menuItems">
          <v-list-group
            v-if="item.items"
            :key="index"
            color="#1F2937"
            no-action
            ripple
            :class="{
              'bg-grey': true,
              'bg-grey-300': item.routeName === $route.name
            }"
          >
            <template v-slot:activator >
              <v-list-item-icon>
                <v-icon color="#1F2937">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="list-txt" v-text="$t(item.title)"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="subItem in item.items"
              :key="subItem.title"
              @click="$router.push({name: subItem.routeName})"
              style="padding-left:24px"
              :class="{
                'bg-grey': true,
                'bg-grey-300': subItem.routeName === $route.name
              }"
            >
              <v-list-item-icon >
                <v-icon color="#1F2937">{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="list-txt" v-text="$t(subItem.title)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item-group
            v-else 
            v-model="selected"
            :key="index"
            color="#E5E7EB"
            :class="{
              'bg-grey': true,
              'bg-grey-300': item.routeName === $route.name
            }"
          >
            <v-list-item  @click="$router.push({name: item.routeName})">
              <v-list-item-icon>
                <v-icon color="#1F2937">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="list-txt" v-text="$t(item.title)"></v-list-item-title>
              </v-list-item-content>
           
            </v-list-item>
            
          </v-list-item-group>
        </template>
        <v-list-item v-if="!authenticated" @click="openLogin()">
          <v-list-item-action><v-icon color="secondary">exit_to_app</v-icon></v-list-item-action>
          <v-list-item-title>
            <span class="list-txt" v-text="$t('global.menu.account.login')">Sign in</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider color="#F3F4F6"></v-divider>
      <v-list dense v-if="resourceItems.length > 0" color="#F3F4F6">
        <v-list-item-group
          color="#F3F4F6"
        >
          <v-list-item
            v-for="(item, i) in resourceItems"
            :key="i"
            @click="launchNewTab(item.link)"
            target="_blank"
          >
            <v-list-item-content>
              <v-list-item-title class="list-txt" v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="primary" dark elevation="0" height="60">
      <v-app-bar-nav-icon @click.stop="toggleMini = !toggleMini"></v-app-bar-nav-icon>
      &nbsp;&nbsp;&nbsp;
      <v-img
        alt="ELB Learning Logo"
        class="shrink hidden-sm-and-down left-logo mb-2 mt-2"
        max-width="44"
        max-height="32"
        src="/content/images/email/elb_logo_white.png"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
      <v-spacer></v-spacer>
      
          <img 
            alt="Studio Logo" 
            src="/content/images/TheStudio_Logo_Light.svg" 
            height="30"
            width="150"
            >
      <v-spacer></v-spacer>
      <toolbar-user-menu 
        :userAccount="userAccount"  
        @logoutClicked="logout()" 
        @accountSettingsClicked="accountSettings"
      />
      
      
    </v-app-bar>
  </div>
</template>

<script lang="ts" src="./jhi-navbar.component.ts">
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list-txt {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
/* identical to box height, or 142% */


/* Gray - 800 */

  color: #1F2937;
}

.bg-grey:hover {
  background-color: rgb(229 231 235);
}

.bg-grey-300 {
  background-color: rgb(209 213 219);
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span{
    display:none;
  }
}

.navbar-title {
  display: inline-block;
  vertical-align: middle;
  color: white;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color:white !important;
}


.v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
    padding-left: 30px;
}

.v-application a {
    color: white;
}

.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
    opacity: 0;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 15px;
}

.v-list--dense .v-list-item, .v-list-item--dense {
    min-height: 20px;
}

hr {
    margin-top: 0rem;
    margin-bottom: 0.2rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.v-application .mt-1 {
    margin-top: 0px!important;
}

.left-logo {
    background-position: left;
}
</style>
