<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div>
          <v-avatar size="32">
            <img v-if="userAccount && userAccount.imageUrl" :src="userAccount.imageUrl" />
            <v-icon v-else color="accent">mdi-account-outline</v-icon>
          </v-avatar>&nbsp;&nbsp;
          <span class="shrink hidden-sm-and-down"><strong>{{ currentUser }}</strong></span>&nbsp;&nbsp;&nbsp;
          <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
        </div>
      </template>

      <v-card>
        <v-card-text>
          <v-row>
            <v-col class="ml-4">
              <v-img
                alt="ELB Learning Logo"
                max-width="30"
                max-height="21.4"
                src="/content/images/email/elb_logo_black.png"
              />
            </v-col>
            <v-col align="end">
              <v-btn class="mt-n4 entity-button-text" text @click="logoutClicked()">
                Sign Out
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-avatar size="100">
                <img v-if="userAccount && userAccount.imageUrl" :src="userAccount.imageUrl" />
                <v-icon v-else color="accent">mdi-account-outline</v-icon>
              </v-avatar>
            </v-col>
            <v-col  v-if="userAccount">
              <v-row>
                <v-col ><h5>{{ currentUser }}</h5></v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col>{{userAccount.email}}</v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col>
                  <span v-for="(role, i) in userAccount.authorities" :key="role">{{i > 0 ? ', ' : ''}}
                      <span>{{getRoleName(role)}}</span>
                  </span>
                </v-col>
              </v-row>
              <v-row class="mt-n4">
                <v-col>
                  <a class="hand" v-on:click="accountSettingsClicked" >
                    Account Settings
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="ma-0 px-2">
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
  
</template>

<script lang="ts" src="./toolbar-user-menu.component.ts">
</script>

<style scoped>
.name-txt {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;

  /* white */

  color: #FFFFFF;
}
</style>