<template>
  <v-app :class="background">
    <reauthenticate v-if="reAuthentication"/>
    <!-- <agreement v-if="legalAgreement" /> -->
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script lang="ts" src="./app.component.ts">
</script>
<style scoped>
.main-area {
  background: #f4f4f4;
}
</style>
