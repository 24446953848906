.v-card {
  box-shadow: $card-shadow;
  border-radius: $border-radius-base;.category {
    margin: 0;
  }.title {
    margin-top: 0;
    line-height: $line-height-base !important;
    letter-spacing: 0 !important;
    font-size: $font-size-h4 !important;
    margin-bottom: $card-title-margin !important;
  }.v-divider {
    border-top: $divider-border;
    margin-left: $card-content-x-padding !important;
    margin-right: $card-content-x-padding !important;
    margin-bottom: 1px;
  }.v-offset {
    top: $card-offset !important;
    margin-bottom: $card-offset !important;.category {
      color: rgba($white, 0.62);
    }.v-card--material__header.v-card {
      padding: $padding-general;
    }
  }.v-card__actions {
    margin: 0 $card-actions-y-margin 0;
    padding: $padding-small 0 $padding-small;
    line-height: $line-height-higher;
  }
}.v-card--material-login.v-card {
  &:not(.v-card--material__header) {
    margin: $card-margin 0 !important;
  }.v-card--material__header {
    border-radius: $border-radius-base + 3;
    min-height: $card-login-min-height;
    padding: 0 !important;.ct-label {
      font-size: $font-size-login-label;
    }
  }.title {
    margin-top: 0;
  }.category {
    margin: 0 !important;
    line-height: $line-height-higher;
    color: $gray-light;
  }.v-card__text {
    padding: $padding-general $card-content-x-padding;
    line-height: $line-height-higher;
  }
}.v-card--material-stats.v-card:not(.v-card--material__header), 
.v-card--material-login.v-card:not(.v-card--material__header) {
  margin: $card-margin 0 !important;
}

.v-card--material-stats.v-card {
  .v-offset {
    position: absolute;.v-card {
      max-width: $card-stats-offset;
      max-height: $card-stats-offset;
      line-height: $card-stats-offset;
      padding: $padding-general !important;
      i {
        font-size: $font-size-big !important;
        line-height: $line-height-high;
        width: $card-stats-icons-dim;
        height: $card-stats-icons-dim;
      }
    }
  }.v-card__text {
    text-align: right;
    padding-top: $padding-small;
    position: relative;
  }.title {
    margin: 0 !important;
    line-height: $line-height-base !important;
    letter-spacing: 0 !important;
    font-size: $font-size-h3 !important;
    small {
      color: $gray-light;
      font-size: $font-size-small-tag;
      line-height: 1;
      font-weight: $font-weight-base;
    }
  }.v-card__actions {
    i {
      position: relative;
      top: -1px;
      font-size: $font-size-default + 2 !important;
    }.caption {
      color: $gray-light;
    }
  }
}.v-card-profile {
  display: inline-block;
  .v-offset {
    top: unset !important;
    margin-bottom: unset !important;
    margin-top: $card-profile-avatar-pos;
  }
  .v-card__text .v-card__text {
    padding-bottom: 0;
  }
  .v-card__text {
    padding: $padding-general;
  }
  img {
  }
}
.v-card--flat {
  background-color: transparent !important;
  box-shadow: none;
  .v-table {
    background-color: transparent;
  }
}
